import useAxiosPrivate from "api/useAxiosPrivate";
import CustomToggleSwitch from "components/Elements/CustomToggleSwitch";
import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  Form,
  Col,
  Row,
  Alert,
} from "reactstrap";
import swal from "sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import Spinner from "react-bootstrap/Spinner";
import useAuth from "hooks/useAuth";

const PlanDetailModal = ({ show, fetchData, toggle }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const isMounted = useRef(true);
  const [name, setName] = useState("BBPS");
  const [route, setRoute] = useState(0);

  const [data, setData] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);

  const { balance } = useAuth();

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();
  const fetchAPIList = async () => {
    try {
      const response = await axiosPrivate.get("/utility/fetch-plan-details", {
        AbortSignal: controller.signal,
      });
      console.log(response.data.data.RDATA);

      setData(response.data.data.RDATA.TOPUP);
    } catch (err) {
      console.log(err);
    }
  };
  console.log(data, "Data");

  useEffect(() => {
    // if (!balance) return;

    Promise.all([fetchAPIList()]);
    return () => {
      controller.abort();
    };
  }, [balance]);

  const columns = [
    {
      text: "#",
      isDummyField: true,
      dataField: "something",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
    },
    {
      text: "Type",
      dataField: "Type",
    },
    {
      text: "Data",
      dataField: "data",
    },
    {
      text: "Desc",
      dataField: "desc",
    },
    {
      text: "rs",
      dataField: "rs",
    },
    {
      text: "Talktime",
      dataField: "talktime",
    },
    {
      text: "Validity",
      dataField: "validity",
    },
  ];

  return (
    <div>
      <Modal
        fullscreen=""
        size="lg"
        isOpen={show}
        backdrop="static"
        keyboard
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>Plan Details</ModalHeader>
        <ModalBody>
          <ToolkitProvider keyField="id" data={data} columns={columns} search>
            {(props) => (
              <>
                <BootstrapTable
                  // wrapperClasses="table-responsive"
                  rowClasses="text-nowrap"
                  {...props.baseProps}
                  noDataIndication={
                    data ? (
                      "Table is Empty"
                    ) : (
                      <Spinner animation="border" variant="primary" />
                    )
                  }
                  headerWrapperClasses="thead-light"
                  bodyClasses="align-items-center"
                  //   pagination={pagination}
                  hover
                  bordered={false}
                  rowStyle={{ backgroundColor: "#ffd4db" }}
                  // condensed
                  bootstrap4
                />
              </>
            )}
          </ToolkitProvider>
        </ModalBody>
        <ModalFooter>
          <Button color="primary">Save</Button>{" "}
          <Button onClick={toggle} color="danger">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default PlanDetailModal;
