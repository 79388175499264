import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { useEffect, useState } from "react";
import useAuth from "hooks/useAuth";
import swal from "sweetalert";
import useAxiosPrivate from "api/useAxiosPrivate";
import ServiceValidate from "components/Elements/ServiceValidate";
import PlanDetailsModal from "../Modals/PlanDetailsModal";

const Prepaid = ({ isPackageDependent }) => {
  const [showModal, setShowModal] = useState(false);
  const toggle = () => {
    setShowModal(!showModal);
  };
  const [data, setData] = useState([]);
  const [operators, setOperators] = useState([]);
  const [operator, setOperator] = useState(0);
  const [circles, setCircles] = useState([]);
  const [circle, setCircle] = useState(0);
  const [amount, setAmount] = useState("");
  const [consumerNumber, setConsumerNumber] = useState("");
  const { auth } = useAuth();
  const [isSubmit, setIsSubmit] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  const [isLoading, setIsLoading] = useState(true);

  const operatorCodes = {};

  useEffect(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (isLoading === false) {
      return;
    }
    const controller = new AbortController();
    let isMounted = true;

    const fetchCircles = async () => {
      try {
        const response = await axiosPrivate.get("/states/list", {
          AbortSignal: controller.signal,
        });
        console.log(response.data);
        isMounted && response.data?.list && setCircles(response.data.list);
      } catch (err) {
        console.error(err?.response ? err.response : err.data);
      }
    };
    const fetchOperators = async () => {
      try {
        const response = await axiosPrivate.get("/operators/list", {
          AbortSignal: controller.signal,
        });
        console.log(response.data);
        isMounted && response.data?.list && setOperators(response.data.list);
      } catch (err) {
        console.error(err?.response ? err.response : err.data);
      }
    };
    Promise.all([fetchCircles(), fetchOperators()]);
  }, [isLoading]);

  useEffect(() => {
    if (consumerNumber.length !== 10) {
      return;
    }
    let isMounted = true;
    const controller = new AbortController();
    const fetchDetails = async () => {
      try {
        var response = await axiosPrivate.get("/utility/fetch-details", {
          params: {
            number: consumerNumber,
          },
          AbortSignal: controller.signal,
          withCredentials: false,
        });
        if (isMounted && response.data.success) {
          response = response.data;
          circles.forEach((item) => {
            if (item.code === response.data.CircleCode) {
              setCircle(item.id);
            }
          });
          operators.forEach((item) => {
            if (item.operatorFetchCode === response.data.OpCode) {
              setOperator(item.id);
            }
          });
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchDetails();
  }, [consumerNumber]);

  useEffect(() => {
    console.log(operator, "operator");
  }, [operator]);

  const resetValues = () => {
    setOperator(0);
    setCircle(0);
    setAmount(0);
    setConsumerNumber("");
  };

  const processPayment = () => {
    if (isSubmit) return;
    setIsSubmit(true);

    const postPayment = async () => {
      try {
        const params = {
          operator: operator,
          circle: circle,
          amount: amount,
          number: consumerNumber,
        };
        const response = await axiosPrivate.post("/service/prepaid", params, {
          withCredentials: false,
        });
        console.log(response.data);
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response.data.success
        ) {
          swal(
            `Recharge for Rs.${amount} on ${consumerNumber} is Submitted!`,
            "",
            "success"
          );
          resetValues();
        }
      } catch (err) {
        console.log(err?.response);
        if (err?.response) {
          return swal(err.response.data.message, "", "warning");
        }
        swal("Something went wrong, please try again later!", "", "error");
        // swal(err.data?.Data.Message || err.data.Message, "", "error");
      } finally {
        setIsSubmit(false);
      }
    };
    postPayment();
  };

  return (
    <>
      {isPackageDependent && <ServiceValidate serviceName="mobile" />}
      <Header />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}

        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Prepaid</h3>
                <small className="form-text text-muted mt-0">
                  Tell us your number and we will figure out the rest
                </small>
                <hr></hr>
              </CardHeader>
              <CardBody style={{ width: "50%" }}>
                <Form>
                  <FormGroup>
                    <Label
                      for="input-mobilenumber"
                      className="form-control-label"
                    >
                      Mobile Number
                    </Label>

                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">☎️</InputGroupAddon>
                      <Input
                        placeholder="Enter Mobile Number"
                        className="form-control"
                        id="input-mobilenumber"
                        type="number"
                        value={consumerNumber}
                        onChange={(e) => setConsumerNumber(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <Label for="input-operator" className="form-control-label">
                      Choose Operator
                    </Label>

                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">☎️</InputGroupAddon>
                      <Input
                        className="form-control"
                        id="input-operator"
                        type="select"
                        value={operator}
                        onChange={(e) => setOperator(e.target.value)}
                      >
                        <option key={0}>Select Operator</option>
                        {operators.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.operatorName}
                          </option>
                        ))}
                      </Input>
                      {/* <InputGroupAddon addonType="append">
                        <Button color="primary" type="button">
                          View Plan
                        </Button>
                      </InputGroupAddon> */}
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup>
                      {/* <InputGroupAddon addonType="prepend">
                        🌎
                      </InputGroupAddon> */}
                      <Input
                        className="form-control"
                        id="input-circle"
                        type="select"
                        value={circle}
                        hidden
                        onChange={(e) => setCircle(e.target.value)}
                      >
                        <option key={0}>Select Circle</option>
                        {circles.map((item) => {
                          return (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </Input>
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <Label for="input-amount" className="form-control-label">
                      Amount
                    </Label>

                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">₹</InputGroupAddon>
                      <Input
                        className="form-control"
                        id="input-amount"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        placeholder="Enter Amount"
                      />

                      <InputGroupAddon addonType="append">
                        <Button color="primary" type="button" onClick={toggle}>
                          View Plan
                        </Button>
                        {showModal ? (
                          <PlanDetailsModal show={showModal} toggle={toggle} />
                        ) : null}
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup row>
                    <Button
                      outline
                      color="primary"
                      type="button"
                      onClick={processPayment}
                    >
                      Payment
                    </Button>
                    <Button color="danger" type="button">
                      Cancel
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Prepaid;
