import routes from "routes.js";
import useGetRoutes from "components/useGetRoutes";
import { Switch, Redirect } from "react-router-dom";

const RenderLayout = ({ LayoutComponent, layout, redirect = true }) => {
  return (
    <>
      <Switch>
        {useGetRoutes(routes, LayoutComponent)}
        {redirect ? <Redirect from="*" to={layout + "/index"} /> : <></>}
      </Switch>
    </>
  );
};
export default RenderLayout;
