import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import RetailerLayout from "layouts/Retailer.js";

import { AuthProvider } from "context/AuthContext";
import RenderLayout from "layouts/RenderLayout";

ReactDOM.render(
  <AuthProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/auth" component={(props) => <AuthLayout {...props} />} />
        <RenderLayout layout="/admin" LayoutComponent={AdminLayout} />
        <RenderLayout layout="/retailer" LayoutComponent={RetailerLayout} />
      </Switch>
    </BrowserRouter>
  </AuthProvider>,
  document.getElementById("root")
);
