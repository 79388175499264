import useAxiosPrivate from "api/useAxiosPrivate";
import { serviceTypes } from "config/constaints";
import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormGroup,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import swal from "sweetalert";

const AddOperatorModal = ({ show, toggle, data, fetchOperators }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const [name, setName] = useState(data?.operatorName || "");
  const [code, setCode] = useState(data?.operatorFetchCode || "");
  const [serviceType, setServiceType] = useState(
    data?.operatorType
      ? serviceTypes.find((item) => item.toLowerCase() === data?.operatorType)
      : "-1"
  );
  console.log("data", data);
  const [maxAmount, setMaxAmount] = useState(data?.maxAmount || 0);
  const [minAmount, setMinAmount] = useState(data?.minAmount || 0);
  const [stopAmount, setStopAmount] = useState(
    data?.stopAmount?.join(",") || ""
  );
  const ADD_OPERATOR = "/operators/add";
  const UPDATE_OPERATOR = "/operators/" + data?.id;

  const resetValues = () => {
    setServiceType("");
    setMaxAmount(0);
    setMinAmount(0);
    setStopAmount(0);
    setCode("");
    setStopAmount("");
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (isSubmit) return;
    setIsSubmit(true);
    if (name === "" || serviceType === "") {
      setIsSubmit(false);
      swal("Enter All Details!", "", "error");
      return;
    }
    try {
      var stopAmounts = stopAmount.split(",");
      var payload = {
        serviceType: serviceType.toLocaleLowerCase(),
        operatorName: name,
        maxAmount,
        minAmount,
        stopAmount: stopAmounts,
        operatorFetchCode: code,
      };
      const response = await axiosPrivate.post(
        data?.id ? UPDATE_OPERATOR : ADD_OPERATOR,
        payload
      );
      if (response.status === 201) {
        swal("Operator Updated Successfully!", "", "success");
        resetValues();
        toggle();
        fetchOperators();
      } else {
        throw Error(response.data);
      }
    } catch (err) {
      swal(err.message, "", "error");
    } finally {
      setIsSubmit(false);
    }
  };

  return (
    <div>
      <Modal
        fullscreen=""
        // size="lg"
        isOpen={show}
        backdrop="static"
        keyboard
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>Add Operator</ModalHeader>
        <ModalBody>
          <FormGroup>
            <label htmlFor="input-name">Operator Name</label>
            <Input
              className="form-control"
              placeholder="Enter Operator Name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              id="input-name"
              name="name"
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="input-type">Service Type</label>
            {console.log("service type", serviceType)}
            <Input
              className="form-control"
              type="select"
              value={serviceType}
              onChange={(e) => setServiceType(e.target.value)}
              id="input-type"
              name="type"
            >
              <option key={0} value={"0"}>
                Select Service
              </option>
              {serviceTypes.map((item, i) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <label htmlFor="input-code">Operator Fetch Code</label>
            <Input
              className="form-control"
              type="text"
              placeholder="Enter Operator Fetch Code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              id="input-code"
              name="code"
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="input-minamount">Minimum Amount</label>
            <Input
              className="form-control"
              type="text"
              placeholder="Enter Minimum Amount"
              value={minAmount}
              onChange={(e) => setMinAmount(e.target.value)}
              id="input-minamount"
              name="minamount"
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="input-maxamount">Maximum Amount</label>
            <Input
              className="form-control"
              type="text"
              placeholder="Enter Maximum Amount"
              value={maxAmount}
              onChange={(e) => setMaxAmount(e.target.value)}
              id="input-maxamount"
              name="maxamount"
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="input-stopamount">Restricted Amounts</label>
            <Input
              className="form-control"
              type="text"
              placeholder="Enter Restricted Amount Seperated with (,)"
              value={stopAmount}
              onChange={(e) => setStopAmount(e.target.value)}
              id="input-stopamount"
              name="stopamount"
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={(e) => submitForm(e)}>
            {data?.id ? "Update" : "Add"} Operator
          </Button>{" "}
          <Button onClick={toggle} color="danger">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default AddOperatorModal;
