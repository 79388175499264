import PrivateRoute from "./PrivateRoute";
import useAuth from "hooks/useAuth";

const MockLayout = ({ children }) => {
  return children;
};

const useGetRoutes = (routes, Layout) => {
  return routes.map((prop, key) => {
    if (prop["subCategories"]) {
      const subCategories = prop.subCategories.map((prop) => {
        if (prop["subCategories"]) {
          const categories = prop.subCategories.map((prop) => {
            var LayoutComponent = prop?.hideSidebar ? MockLayout : Layout;
            return (
              <PrivateRoute
                path={prop.layout + prop.path}
                allowedRoles={prop.allowedRoles}
                name={prop.name}
                component={({ location }) => {
                  return (
                    <LayoutComponent location={location}>
                      <prop.component
                        name={prop.name}
                        isPackageDependent={prop?.isPackageDependent || false}
                      />
                    </LayoutComponent>
                  );
                }}
                key={prop.name}
              />
            );
          });
          return categories;
        } else {
          var LayoutComponent = prop?.hideSidebar ? MockLayout : Layout;
          return (
            <PrivateRoute
              path={prop.layout + prop.path}
              allowedRoles={prop.allowedRoles}
              name={prop.name}
              component={({ location }) => {
                return (
                  <LayoutComponent location={location}>
                    <prop.component
                      name={prop.name}
                      isPackageDependent={prop?.isPackageDependent || false}
                    />
                  </LayoutComponent>
                );
              }}
              key={prop.name}
            />
          );
        }
      });
      return subCategories;
    } else {
      var LayoutComponent = prop?.hideSidebar ? MockLayout : Layout;
      return (
        <PrivateRoute
          path={prop.layout + prop.path}
          allowedRoles={prop.allowedRoles}
          name={prop.name}
          component={({ location }) => {
            return (
              <LayoutComponent location={location}>
                <prop.component
                  name={prop.name}
                  isPackageDependent={prop?.isPackageDependent || false}
                />
              </LayoutComponent>
            );
          }}
          key={prop.name}
        />
      );
    }
  });
};

export default useGetRoutes;
