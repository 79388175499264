import useAxiosPrivate from "api/useAxiosPrivate";
import { serviceTypes } from "config/constaints";
import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormGroup,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import swal from "sweetalert";

const AddOperatorFetchModal = ({ show, toggle, data, fetchOperatorsPlan }) => {
  const [isSubmit, setIsSubmit] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  const [name, setName] = useState(data?.name ? data.name : "");
  const [fetchurl, setfetchurl] = useState(data?.fetchUrl ? data.fetchUrl : "");
  const ADD_OPERATOR = "/extras/operator-fetch/add";
  const UPDATE_OPERATOR = "/extras/operator-fetch/" + data?._id;

  const resetValues = () => {
    setName("");
    setfetchurl("");
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (isSubmit) return;
    setIsSubmit(true);
    if (name === "" || fetchurl === "") {
      setIsSubmit(false);
      swal("Enter All Details!", "", "error");
      return;
    }
    try {
      var payload = {
        name: name,
        fetchUrl: fetchurl,

      };
      const response = await axiosPrivate.post(data?._id ? UPDATE_OPERATOR : ADD_OPERATOR, payload);
      if (response.status === 200) {
        swal("Operator Fetch API Added Successfully!", "", "success");
        resetValues();
        toggle();
        fetchOperatorsPlan();
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      swal(err.message, "", "error");
    } finally {
      setIsSubmit(false);
    }
  };

  return (
    <div>
      <Modal
        fullscreen=""
        // size="lg"
        isOpen={show}
        backdrop="static"
        keyboard
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>Add Operator Fetch API</ModalHeader>
        <ModalBody>
          <FormGroup>
            <label htmlFor="input-name">Name</label>
            <Input
              className="form-control"
              placeholder="Enter Name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              id="input-name"
              name="name"
            />
          </FormGroup>

          <FormGroup>
            <label htmlFor="input-fetchurl">Fetch URL</label>
            <Input
              className="form-control"
              type="text"
              placeholder="Enter Fetch URL"
              value={fetchurl}
              onChange={(e) => setfetchurl(e.target.value)}
              id="input-fetchurl"
              name="fetchurl"
            />
          </FormGroup>

        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={(e) => submitForm(e)}>
            {data?.name ? "Update" : "Add"} API
          </Button>{" "}
          <Button onClick={toggle} color="danger">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default AddOperatorFetchModal;
